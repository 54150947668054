@tailwind base;
@tailwind components;
@tailwind utilities;

*:not(.no-modify) > a[href] {
    @apply underline text-blue-200 hover:text-blue-400 visited:text-purple-500;
}

.suspense-loader {
    @apply w-fit m-auto mt-16;
}
